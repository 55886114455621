import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { BrowsePage, getPageLayout } from '@/features';
import { PageType } from '@/types/page';
import { NOT_FOUND_ROUTE } from '@/types/routes';

export default function OrganizationBySlug(): ReactNode {
  const { push, isReady, query } = useRouter();
  const { orgSlug } = query;

  useEffect(() => {
    if (isReady) {
      if (!orgSlug || Array.isArray(orgSlug)) {
        push(NOT_FOUND_ROUTE);
      }
    }
  }, [isReady, push, orgSlug]);

  if (!orgSlug || Array.isArray(orgSlug)) {
    return <></>;
  }

  return <BrowsePage pageType={PageType.SUBDOMAIN_FEED} orgSlug={orgSlug} />;
}

OrganizationBySlug.getLayout = (page: ReactElement) =>
  getPageLayout(page, PageType.SUBDOMAIN_FEED);
